import React from 'react';
import {Flex, Spinner, Text} from '@chakra-ui/react';

const LoadingView: React.FC = () => (
    <Flex
        direction="column"
        align="center"
        justify="center"
        height="100vh"
        bg="#080808"
    >
        <Spinner
            thickness="6px"
            speed="0.65s"
            color={'#30E2FF'}
            size="xl"
            width="64px"
            height="64px"
        />
        {/*<Text color="white" fontSize="xl" fontWeight="bold" mt={4}>Loading...</Text>*/}
    </Flex>
);

export default LoadingView;