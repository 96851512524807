import {makeAutoObservable} from 'mobx';

import type RootStore from './rootStore';
import axios from "axios";
import {Item} from "../Components/Queue/Queue";
import {HTTP_HOST, CREATE_BASE_URL, FAVOR_BASE_URL} from '../constants/global';

export default class PlayerStore {
    currentQueueName: string = "";
    rootStore: RootStore;
    currentAudioSrc: AudioSrc | null = null;
    currentPlayIndex: number = -1;
    totalPlayCount: number = 0;
    recommendedDataList: AudioSrc[] = [];
    recentlyGeneratedDataList: AudioSrc[] = [];
    exploreDataList: AudioSrc[] = [];
    randomDataList: AudioSrc[] = [];
    createDataList: AudioSrc[] = [];
    favorDataList: AudioSrc[] = [];
    recommendedLimitDatetime: string = '';
    exploreSkip: number = 0;
    randomSkip: number = 0;
    createLimitId: number = 0;
    favorLimitDatetime: string = '';
    currentWebSocket: WebSocket | null = null;
    remainGenerateCount: number = 0;
    remainLoopDownloadCount: number = 0;
    limitGenerateCount: number = 0;
    limitLoopDownloadCount: number = 0;
    currentPlanName: string = "Free";
    monthlyResetTime: string = "";
    currentMaxLoopedDuration = "3min";

    isRecommendedLoaded: boolean = false;
    isRecentlyGeneratedLoaded: boolean = false;
    isExploreLoaded: boolean = false;
    isCreateLoaded: boolean = false;
    isFavorLoaded: boolean = false;

    isRecommendedEnd: boolean = false;
    isExploreEnd: boolean = false;
    isCreateEnd: boolean = false;
    isFavorEnd: boolean = false;

    createBaseUrl: string = CREATE_BASE_URL;
    favorBaseUrl: string = FAVOR_BASE_URL;

    inputMelodyId: number = -1;
    melodyUrl: string = '';
    melodyFileName: string = '';
    inputMelodyMIDI: any = null;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {rootStore: false});
        this.rootStore = rootStore;
        // console.log("PlayerStore")
    }

    setCurrentAudioSrc = (queueName: string, index: number, audioSrc: AudioSrc | null): void => {
        // console.log("setCurrentAudioSrc: " +  queueName)
        if (audioSrc != null){
            audioSrc.isLoading = true;
        }
        this.currentQueueName = queueName
        if (this.currentAudioSrc !== null){
            this.currentAudioSrc.isLoading = false;
        }
        this.currentAudioSrc = audioSrc;
        this.currentPlayIndex = index
        this.totalPlayCount = this.getDataList(queueName).length
        if (audioSrc !== null){
            audioSrc.notPlayed = false
        }
    };

    setDataList = (queueName: string, dataList: AudioSrc[]): void => {
        if (queueName === 'generated'){
            this.recentlyGeneratedDataList = dataList;
        }else if (queueName === 'recommended') {
            this.recommendedDataList = dataList;
        }else if (queueName === 'explore') {
            this.exploreDataList = dataList;
        }else if (queueName === 'create') {
            this.createDataList = dataList;
        }else if (queueName === 'favor') {
            this.favorDataList = dataList;
        }
        if (this.currentQueueName !== ''){
            this.totalPlayCount = this.getDataList(this.currentQueueName).length
        }
    };

    getDataList = (queueName: string): AudioSrc[] => {
        if (queueName === 'generated'){
            return this.recentlyGeneratedDataList;
        }else if (queueName === 'recommended') {
            return this.recommendedDataList;
        }else if (queueName === 'explore') {
            return this.exploreDataList;
        }else if (queueName === 'create') {
            return this.createDataList;
        }else if (queueName === 'favor') {
            return this.favorDataList;
        }
        return []
    };

    prev = (): void => {
        if (this.currentPlayIndex > 0 && this.currentQueueName !== ''){
            let newIdx = this.currentPlayIndex - 1
            this.setCurrentAudioSrc(this.currentQueueName, newIdx, this.getDataList(this.currentQueueName)[newIdx])
        }
    };

    next = (): void => {
        if (this.currentPlayIndex >= 0 && this.currentPlayIndex < this.totalPlayCount - 1 && this.currentQueueName !== ''){
            let newIdx = this.currentPlayIndex + 1
            this.setCurrentAudioSrc(this.currentQueueName, newIdx, this.getDataList(this.currentQueueName)[newIdx])
        }
    };

    deleteGenerated = (queueName: string, deleteSrc: string, token: string, index: number): void => {
        if (queueName === 'generated') {
            const newDataList = this.recentlyGeneratedDataList.filter(({src}) => src !== deleteSrc,);
            this.recentlyGeneratedDataList = newDataList || []
        }else if (queueName === 'create') {
            const newDataList = this.createDataList.filter(({src}) => src !== deleteSrc,);
            this.createDataList = newDataList || []
        }

        if (this.currentQueueName === 'generated' || this.currentQueueName === 'create') {
            if (this.currentPlayIndex == index) {
                this.setCurrentAudioSrc(this.currentQueueName, -1, null)
            }else if (this.currentPlayIndex > index){
                this.currentPlayIndex -= 1
            }
            this.totalPlayCount = this.getDataList(this.currentQueueName).length
        }

        if (token !== '') {
            const url = HTTP_HOST + "/api/create/delete-make/?name=" + deleteSrc;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            axios.post(url)
                .then(response => {
                })
                .catch(error => {
                    console.log(error)
                });
        }
    };

    getSkip = (queueName: string): number => {
        if (queueName === 'generated'){
        }else if (queueName === 'recommended') {
            return 0;
        }else if (queueName === 'explore') {
            return this.exploreSkip;
        }else if (queueName === 'create') {
        }else if (queueName === 'favor') {
            return 0;
        }
        return 0
    };

    setSkip = (queueName: string, skip: number): void => {
        if (queueName === 'generated'){
        }else if (queueName === 'recommended') {
        }else if (queueName === 'explore') {
            this.exploreSkip = skip;
        }else if (queueName === 'create') {
        }else if (queueName === 'favor') {
        }
    };

    getLimitDatetime = (queueName: string): string => {
        if (queueName === 'recommended') {
            return this.recommendedLimitDatetime
        }else if (queueName === 'favor') {
            return this.favorLimitDatetime
        }
        return ''
    };

    setLimitDatetime = (queueName: string, limitDatetime: string): void => {
        if (queueName === 'recommended') {
            this.recommendedLimitDatetime = limitDatetime
        }else if (queueName === 'favor') {
            this.favorLimitDatetime = limitDatetime
        }
    };

    setIsLoaded = (queueName: string, b: boolean): void => {
        if (queueName === 'generated'){
            this.isRecentlyGeneratedLoaded = b;
        }else if (queueName === 'recommended') {
            this.isRecommendedLoaded = b;
        }else if (queueName === 'explore') {
            this.isExploreLoaded = b;
            if (!b){
                this.setQueueEnd(queueName, false)
                this.exploreDataList = []
            }
        }else if (queueName === 'create') {
            this.isCreateLoaded = b;
        }else if (queueName === 'favor') {
            this.isFavorLoaded = b;
        }
    };

    getIsLoaded = (queueName: string): boolean => {
        if (queueName === 'generated'){
            return this.isRecentlyGeneratedLoaded;
        }else if (queueName === 'recommended') {
            return this.isRecommendedLoaded;
        }else if (queueName === 'explore') {
            return this.isExploreLoaded;
        }else if (queueName === 'create') {
            return this.isCreateLoaded;
        }else if (queueName === 'favor') {
            return this.isFavorLoaded;
        }
        return true
    };

    isQueueEnd = (queueName: string): boolean => {
        if (queueName === 'generated'){
            return false;
        }else if (queueName === 'recommended') {
            return this.isRecommendedEnd;
        }else if (queueName === 'explore') {
            return this.isExploreEnd;
        }else if (queueName === 'create') {
            return this.isCreateEnd;
        }else if (queueName === 'favor') {
            return this.isFavorEnd;
        }
        return false
    }

    setQueueEnd = (queueName: string, b: boolean): void => {
        if (queueName === 'generated'){
        }else if (queueName === 'recommended') {
            this.isRecommendedEnd = b;
        }else if (queueName === 'explore') {
            this.isExploreEnd = b;
        }else if (queueName === 'create') {
            this.isCreateEnd = b;
        }else if (queueName === 'favor') {
            this.isFavorEnd = b;
        }
    }

    fillDataList = async (baseUrl: string, token: string, queueName: string, setLoading: any=null, firstFill=false, getCount=20) => {
        if (this.getIsLoaded(queueName) && firstFill) {
            return
        }
        let actualSkip = 0
        let url = ''
        if (!firstFill && this.getDataList(queueName).length > 0){
            if (queueName === 'favor' || queueName === 'recommended') {
                let limitDatetime = this.getLimitDatetime(queueName)
                if (limitDatetime === ''){
                    return
                }
                url = baseUrl + 'limit_iso_dt=' + encodeURIComponent(limitDatetime) + '&limit=' + getCount;
            }else if (queueName === 'generated' || queueName === 'create'){
                if (queueName === 'create'){
                    if (this.createLimitId == 0){
                        return
                    }
                    url = baseUrl + 'limit_id=' + this.createLimitId + '&limit=' + getCount;
                }else{
                    return
                }
            }else{
                actualSkip = this.getSkip(queueName)
                url = baseUrl + 'skip=' + actualSkip + '&limit=' + getCount;
            }
        }else{
            url = baseUrl + 'limit=' + getCount;
            if (queueName === 'explore') {
                if (baseUrl.indexOf('/random/') !== -1 && this.randomDataList.length > 0) {
                    this.exploreDataList = this.randomDataList
                    this.exploreSkip = this.randomSkip
                    if (queueName === 'explore' && this.currentQueueName === 'explore') {
                        this.currentPlayIndex = -1
                        this.totalPlayCount = 0
                    }
                    return
                }
            }
        }
        if (setLoading !== null){
            setLoading(true);
        }
        try {
            let config = {
                headers: {
                    'accept': 'application/json',
                    'Authorization': ''
                }
            };
            if (token !== '') {
                config = {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
            }
            const response = await axios.get(url, config);
            // console.log(response.data)
            let tracks = null
            if (queueName === 'favor' || queueName === 'recommended'){
                tracks = response.data.tracks
                this.setLimitDatetime(queueName, response.data.last_dt)
            }else if (queueName === 'generated' || queueName === 'create'){
                tracks = response.data.tracks
                if (queueName === 'create'){
                    this.createLimitId = response.data.last_id
                }
            }else{
                tracks = response.data
            }

            const parseData = tracks.map((item: Item) => ({
                src: item.file_name,
                title: item.title,
                melody_id: item.melody_id,
                duration: item.duration,
                custom_download: item.custom_download,
                favor: item.favor,
                bpm: item.bpm,
                thumbnail: item.thumbnail ? 'data:image/png;base64,' + item.thumbnail : "",
                tags: item.tags ? item.tags : [],
                state: item.state ? item.state : '',
                notPlayed: item.not_played ? item.not_played : false
            }));
            if (queueName !== 'generated'){
                if (parseData.length < getCount){
                    this.setQueueEnd(queueName, true)
                }
            }
            if (firstFill){
                this.setDataList(queueName, parseData);
                this.setSkip(queueName, getCount);
                if (queueName === 'explore' && this.currentQueueName === 'explore') {
                    this.currentPlayIndex = -1
                    this.totalPlayCount = 0
                }
            }else{
                this.setDataList(queueName, [...this.getDataList(queueName), ...parseData]);
                this.setSkip(queueName, actualSkip + getCount);
            }
            if (queueName === 'explore') {
                if (baseUrl.indexOf('/random/') !== -1) {
                    this.randomDataList = this.exploreDataList
                    this.randomSkip = this.exploreSkip
                }
            }
            if (setLoading !== null) {
                setLoading(false);
            }
            this.setIsLoaded(queueName, true)
            console.log(this.getDataList(queueName).length + ',' + parseData.length + ', ' + url)
        } catch (error) {
            if (setLoading !== null) {
                setLoading(false);
            }
        }
    };

    updateDataList = (srcDataList: AudioSrc[], dataList: AudioSrc[]): void => {
        if (dataList.length > 0){
            dataList.forEach((updateItem) => {
                const index = srcDataList.findIndex((item) => item.src === updateItem.src);
                if (index !== -1) {
                    srcDataList[index] = { ...srcDataList[index], ...updateItem };
                }
            });
        }
    };

    updateGenerateList = (dataList: AudioSrc[]): void => {
        if (dataList.length > 0){
            // console.log(dataList)
            // const newDataList = [...this.recentlyGeneratedDataList];
            // dataList.forEach((updateItem) => {
            //     const index = this.recentlyGeneratedDataList.findIndex((item) => item.src === updateItem.src);
            //     if (index !== -1) {
            //         newDataList[index] = { ...this.recentlyGeneratedDataList[index], ...updateItem };
            //     }
            // });
            // this.recentlyGeneratedDataList = newDataList

            this.updateDataList(this.recentlyGeneratedDataList, dataList)
            if (this.isCreateLoaded){
                this.updateDataList(this.createDataList, dataList)
            }
        }
    };

    addGenerateList = (dataList: AudioSrc[]): void => {
        if (dataList.length > 0) {
            this.recentlyGeneratedDataList = [...dataList, ...this.recentlyGeneratedDataList]
            if (this.isCreateLoaded){
                this.createDataList = [...dataList, ...this.createDataList]
            }

            if (this.currentQueueName === 'generated' || this.currentQueueName === 'create') {
                this.currentPlayIndex += dataList.length
                this.totalPlayCount = this.getDataList(this.currentQueueName).length
            }
        }
    }

    generateMusic = (
        prompt: string,
        melodyId: number,
        promptStrength: number,
        duration: number,
        token: string,
        formData: any=null,
    ): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (prompt === '') {
                prompt = "Electronic Dance, Bright and groovy";
            }
            prompt = encodeURIComponent(prompt);
            const url = HTTP_HOST + "/api/create/make/?prompt=" + prompt + "&melody_id=" + melodyId + "&correlation_coef=" + promptStrength + "&duration=" + duration;
            console.log(url);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            axios.post(url, formData)
                .then(response => {
                    // console.log(response.data)
                    console.log("melody id: " + response.data.melody_id)
                    if (formData !== null){
                        this.setInputMelodyId(response.data.melody_id)
                    }
                    this.setRemainGenerateCount(response.data.remain_generate);
                    const dataList = JSON.parse(response.data.generate_info).map((item: Item) => ({
                        src: item.file_name,
                        title: item.title,
                        melody_id: item.melody_id,
                        duration: item.duration,
                        custom_download: item.custom_download,
                        favor: item.favor,
                        bpm: item.bpm,
                        thumbnail: item.thumbnail ? 'data:image/png;base64,' + item.thumbnail : "",
                        tags: item.tags ? item.tags : [],
                        state: item.state ? item.state : '',
                        notPlayed: item.not_played ? item.not_played : false
                    }));
                    this.addGenerateList(dataList)
                    resolve(true);
                })
                .catch(error => {
                    console.log(error);
                    resolve(false);
                });
        });
    };

    setCurrentWebSocket = (webSocket: WebSocket | null): void => {
        this.currentWebSocket = webSocket
    };

    setRemainGenerateCount = (count: number): void => {
        this.remainGenerateCount = count
    };

    setRemainLoopDownloadCount = (count: number): void => {
        this.remainLoopDownloadCount = count
    };

    setLimitGenerateCount = (count: number): void => {
        this.limitGenerateCount = count
    };

    setLimitLoopDownloadCount = (count: number): void => {
        this.limitLoopDownloadCount = count
    };

    setCurrentPlanName = (planName: string): void => {
        this.currentPlanName = planName
        if (planName === 'Free'){
            this.currentMaxLoopedDuration = '3min'
        }else if (planName === 'Personal') {
            this.currentMaxLoopedDuration = '10min'
        }else if (planName === 'Pro') {
            this.currentMaxLoopedDuration = '20min'
        }else{
            this.currentMaxLoopedDuration = '3min'
        }
    };

    setMonthlyResetTime = (resetTime: string): void => {
        this.monthlyResetTime = resetTime
    };

    setFavor = (queueName: string, audioSrc: AudioSrc, b: boolean): void => {
        if (queueName !== 'favor'){
            if (this.isFavorLoaded){
                let isExist = false
                this.favorDataList.forEach((item, index) => {
                    if (item.src === audioSrc.src){
                        this.favorDataList[index].favor = b
                        isExist = true
                    }
                });

                if (!isExist){
                    this.favorDataList = [audioSrc, ...this.favorDataList]
                    if (this.currentQueueName === 'favor'){
                        this.totalPlayCount += 1
                        this.currentPlayIndex += 1
                    }
                }
            }
        }
        if (queueName !== 'recommended'){
            this.recommendedDataList.forEach((item, index) => {
                if (item.src === audioSrc.src){
                    this.recommendedDataList[index].favor = b
                }
            });
        }
        if (queueName !== 'explore'){
            this.exploreDataList.forEach((item, index) => {
                if (item.src === audioSrc.src){
                    this.exploreDataList[index].favor = b
                }
            });
        }
    };

    playerStoreReset = (): void => {
        this.currentQueueName = "";
        this.currentAudioSrc = null;
        this.currentPlayIndex = -1;
        this.totalPlayCount = 0;
        this.recommendedDataList = [];
        this.recentlyGeneratedDataList = [];
        this.exploreDataList = [];
        this.randomDataList = [];
        this.createDataList = [];
        this.favorDataList = [];
        this.recommendedLimitDatetime = '';
        this.exploreSkip = 0;
        this.randomSkip = 0;
        this.createLimitId = 0;
        this.favorLimitDatetime = '';
        this.currentWebSocket = null;
        this.remainGenerateCount = 0;
        this.remainLoopDownloadCount = 0;
        this.limitGenerateCount = 0;
        this.limitLoopDownloadCount = 0;
        this.currentPlanName = "Free";
        this.monthlyResetTime = "";
        this.currentMaxLoopedDuration = "3min";

        this.isRecommendedLoaded = false;
        this.isRecentlyGeneratedLoaded = false;
        this.isExploreLoaded = false;
        this.isCreateLoaded = false;
        this.isFavorLoaded = false;

        this.isRecommendedEnd = false;
        this.isExploreEnd = false;
        this.isCreateEnd = false;
        this.isFavorEnd = false;

        this.createBaseUrl = CREATE_BASE_URL;
        this.favorBaseUrl = FAVOR_BASE_URL;

        this.inputMelodyId = -1;
        this.melodyUrl = '';
        this.melodyFileName = '';
    };

    setMonthlyUsage = (data: any): void => {
        this.setCurrentPlanName(data.plan_name)
        this.setLimitGenerateCount(data.limit_generate_count)
        this.setLimitLoopDownloadCount(data.limit_loop_download)
        this.setRemainGenerateCount(data.remain_generate)
        this.setRemainLoopDownloadCount(data.remain_loop_download)
        this.setMonthlyResetTime(data.reset_time)
    };

    getGeneratingCount = (): number => {
        let generatingCount = 0
        for (let i = 0; i < this.recentlyGeneratedDataList.length && i < 10; i++) {
            let curState = this.recentlyGeneratedDataList[i].state
            if (curState === 'doing' || curState === 'queuing'){
                generatingCount += 1
            }
        }
        return generatingCount
    };

    setMelodyUrl = (url: string): void => {
        console.log("setMelodyUrl:" + url)
        this.melodyUrl = url
    };

    setMelodyFileName = (fileName: string): void => {
        this.melodyFileName = fileName
    };

    setInputMelodyId = (melodyId: number): void => {
        this.inputMelodyId = melodyId
    };

    setInputMelodyMIDI = (inputMelodyMIDI: any): void => {
        this.inputMelodyMIDI = inputMelodyMIDI
    };
}