import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {ChakraProvider} from '@chakra-ui/react'
import theme from './customTheme'
import RootStore, { RootStoreProvider } from './stores/rootStore';
const store = new RootStore();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

//设置根元素的样式，避免滚动条出现
document.body.style.margin = '0';
document.body.style.overflow = 'hidden';

root.render(
    // <React.StrictMode>
    <RootStoreProvider value={store}>
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ChakraProvider>
    </RootStoreProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
