import React from 'react';
import { Box, Heading, Text, Link, Flex, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <Box py={6} px={50} bg={'#080808'} overflowY="auto" maxH="calc(100vh)">
            <Flex justify="center" align="center" mb={4}>
                <Heading as="h1" size="xl" color={'white'}>
                    Privacy Policy
                </Heading>
                <Box ml="auto">
                    <Button as={RouterLink} to="/" variant="outline">
                        Back
                    </Button>
                </Box>
            </Flex>

            <Text mb={4} color={'white'}>
                At AIBeatX, we highly value the privacy of our visitors. This Privacy Policy document contains the types of information that AIBeatX collects and records and how we use it. If you have any questions or need more information about our Privacy Policy, please feel free to contact us via email at <Link href="mailto:support@aibeatx.com" color="#30E2FF">support@aibeatx.com</Link>. This Privacy Policy applies only to our online activities and is valid for visitors to our website regarding the information they shared and/or collected at AIBeatX. This policy does not apply to any information collected offline or through channels other than this website.
            </Text>

            <Heading as="h2" size="lg" mb={4} color={'white'}>
                Consent
            </Heading>
            <Text mb={4} color={'white'}>
                By using our website, you hereby consent to our Privacy Policy and agree to its terms.
            </Text>

            <Heading as="h2" size="lg" mb={4} color={'white'}>
                Information We Collect
            </Heading>
            <Text mb={2} color={'white'}>
                The information we collect can be categorized as follows:
            </Text>
            <Heading as="h3" size="md" mb={2} color={'white'}>
                1. Basic Data
            </Heading>
            <Text mb={2} color={'white'}>
                From the first moment you interact with our site, we are collecting data. Sometimes you provide data to us, and other times data is collected automatically.
            </Text>
            <Text mb={2} color={'white'}>
                When you pay for a service, we may ask for your name (or your organization’s name), payment details, and email address. Providing this information is necessary for working with you. We use your personal information to process orders, deliver musical items, process payments, and communicate with you about orders.
            </Text>
            <Heading as="h3" size="md" mb={2} color={'white'}>
                2. Contact Forms
            </Heading>
            <Text mb={2} color={'white'}>
                If you complete a contact form, the information is sent to AIBeatX and is not shared with any third party. Sometimes we use specialized third-party contact form services or other services to conduct surveys and research, in which case the information may be available to these third parties and subject to separate agreements.
            </Text>
            <Heading as="h3" size="md" mb={2} color={'white'}>
                3. Cookies
            </Heading>
            <Text mb={2} color={'white'}>
                If you register on our site, you may opt-in to save your name, email address, and website in cookies. These are for your convenience so that you do not have to fill in your details again. If you have an account and log in to the AIBeatX site, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.
            </Text>
            <Heading as="h3" size="md" mb={2} color={'white'}>
                4. Analytics
            </Heading>
            <Text mb={2} color={'white'}>
                We use analytics tools to see where our visitors are from and what pages they visit. We do not collect individual, identifiable information about you through analytics.
            </Text>
            <Heading as="h3" size="md" mb={2} color={'white'}>
                5. Direct Marketing
            </Heading>
            <Text mb={2} color={'white'}>
                We may use your personal information to display interest-based ads for features, products, and services that might be of interest to you.
            </Text>
            <Heading as="h3" size="md" mb={2} color={'white'}>
                6. Payments
            </Heading>
            <Text mb={4} color={'white'}>
                We may receive information about your payment details through third-party services (such as Stripe) that maintain all your financial information.
            </Text>

            <Heading as="h2" size="lg" mb={4} color={'white'}>
                How We Use Your Information
            </Heading>
            <Text mb={2} color={'white'}>
                We use the information we collect in various ways, including but not limited to:
            </Text>
            <Text mb={2} color={'white'}>- Provide, operate, and maintain our website</Text>
            <Text mb={2} color={'white'}>- Improve, personalize, and expand our website</Text>
            <Text mb={2} color={'white'}>- Understand and analyze how you use our website</Text>
            <Text mb={2} color={'white'}>- Develop new products, services, features, and functionality</Text>
            <Text mb={4} color={'white'}>- Send you emails if you have subscribed</Text>

            <Heading as="h2" size="lg" mb={4} color={'white'}>
                Whom We Share Your Data With
            </Heading>
            <Text mb={4} color={'white'}>
                We do not share your data with any third party, except as requested by the court or authorized government institutions.
            </Text>

            <Heading as="h2" size="lg" mb={4} color={'white'}>
                How Long We Retain Your Data
            </Heading>
            <Text mb={4} color={'white'}>
                For users that register on the AIBeatX website, we store the personal information they provide in their user profile as long as it is necessary. All users can see, edit, or delete their personal information at any time. However, this does not include any data we are obliged to keep for administrative, legal, or security purposes. Website administrators can also see and edit that information.
            </Text>

            <Heading as="h2" size="lg" mb={4} color={'white'}>
                What Rights You Have Over Your Data
            </Heading>
            <Text mb={4} color={'white'}>
                If you have an account on the AIBeatX site or have made orders, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
            </Text>

            <Heading as="h2" size="lg" mb={4} color={'white'}>
                Contact Information
            </Heading>
            <Text mb={4} color={'white'}>
                You can contact us at our email address <Link href="mailto:support@aibeatx.com" color="#30E2FF">support@aibeatx.com</Link>.
                <br />
                Country: Singapore
                <br />
                Manager: Bossco Huang
            </Text>

            <Heading as="h2" size="lg" mb={4} color={'white'}>
                Applicable Laws
            </Heading>
            <Text mb={4} color={'white'}>
                AIBeatX respects the privacy of its online visitors and users and complies with applicable data protection laws (such as CalOPPA, the CCPA, Shine the Light, and the Eraser Law, etc.) for the protection of your privacy.
            </Text>

            <Heading as="h2" size="lg" mb={4} color={'white'}>
                Other
            </Heading>
            <Text mb={4} color={'white'}>
                AIBeatX’s various services and products may be subject to separate agreements (including the privacy policy). If you use these products and services, please follow the rules relating to those products and services.
            </Text>

        </Box>
    );
};

export default PrivacyPolicy;
