import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Box} from "@chakra-ui/react";
import {GOOGLE_OAUTH_CALLBACK_URL} from "./constants/global";
import LoadingView from "./Components/LoadingView";
import {useStore} from "./hooks";

const OAuthCallback = () => {
    const { uiStore } = useStore();
    const { setOAuthLoading } = uiStore;
    const navigate = useNavigate();

    useEffect(() => {
        const handleOAuth = async () => {
            try {
                const query = window.location.search;
                // console.log("handleOAuth:" + query)
                const callbackUrl = GOOGLE_OAUTH_CALLBACK_URL + query;
                const response = await fetch(callbackUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                localStorage.setItem("token", data.access_token);
                setOAuthLoading(false)
                navigate("/");
            } catch (error) {
                setOAuthLoading(false)
                console.error("Error handling OAuth success:", error);
            }
        };
        handleOAuth();
    }, [navigate]);

    return <LoadingView />
};

export default OAuthCallback;
