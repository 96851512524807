import {configure} from 'mobx';
import {createContext} from 'react';

import PlayerStore from './playerStore';
import UiStore from './uiStore';

class RootStore {
    playerStore = new PlayerStore(this);
    uiStore = new UiStore(this);

    constructor() {
        configure({
            enforceActions: 'never',
        });
    }
}

export const RootStoreContext = createContext({} as RootStore);

export const RootStoreProvider = RootStoreContext.Provider;

export default RootStore;
