import {makeAutoObservable} from 'mobx';

import type RootStore from './rootStore';

export default class UiStore {
    rootStore: RootStore;
    filterTags: string[] = [];
    filterDurationRange: string = '';
    filterBpmRange: string = '';
    filterKey: string = '';
    isNarrowView: boolean = true;
    naviBarButtonSize: string = 'md'
    naviBarFontSize: string = 'lg'
    isQuickCreateOpen: boolean = false;
    quickCreateMelodyId: number = -1;
    isGiftSubscriptionOpen: boolean = false;
    quickCreateInputPrompt: string = '';
    isGenerateLoading: boolean = false;
    isOAuthLoading: boolean = false;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {rootStore: false});
        this.rootStore = rootStore;
        // console.log("UiStore")
    }

    setFilterTags = (tags: string[]): void => {
        this.filterTags = tags
        // console.log(tags)
    };

    setFilterDurationRange = (durationRange: string): void => {
        this.filterDurationRange = durationRange
        // console.log(durationRange)
    };

    setFilterBpmRange = (bpmRange: string): void => {
        this.filterBpmRange = bpmRange
        // console.log(bpmRange)
    };

    setFilterKey = (key: string): void => {
        this.filterKey = key
        // console.log(key)
    };

    setNarrowView = (b: boolean): void => {
        this.isNarrowView = b
        if (b){
            this.naviBarButtonSize = 'sm'
            this.naviBarFontSize = 'md'
        }else{
            this.naviBarButtonSize = 'md'
            this.naviBarFontSize = 'lg'
        }
    }

    setQuickCreateOpen = (b: boolean): void => {
        this.isQuickCreateOpen = b;
    }

    setQuickCreateMelodyId = (id: number): void => {
        this.quickCreateMelodyId = id;
    }

    setGiftSubscriptionOpen = (b: boolean): void => {
        this.isGiftSubscriptionOpen = b;
    }

    setQuickCreateInputPrompt = (prompt: string): void => {
        this.quickCreateInputPrompt = prompt
    }

    setGenerateLoading = (b: boolean): void => {
        this.isGenerateLoading = b;
    }

    setOAuthLoading = (b: boolean): void => {
        this.isOAuthLoading = b;
    }
}
