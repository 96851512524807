import React, {useState, useEffect, Suspense, lazy } from 'react';
import {AbsoluteCenter, Box, Flex, Button} from '@chakra-ui/react'
import { Route, Routes } from 'react-router-dom';
import LoadingView from "./Components/LoadingView";
import OAuthCallback from "./OAuthCallback";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
const MainPageContent = lazy(() => import('./MainPageContent'));

const App = (() => {
    return (
        <Suspense fallback={<LoadingView />}>
            <Routes>
                <Route path="*" element={<MainPageContent />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/oauth-callback" element={<OAuthCallback />} />
            </Routes>
        </Suspense>
    );
});

export default App;
