import { extendTheme } from "@chakra-ui/react";

import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define a custom variant
const colorfulVariant = definePartsStyle((props) => {
    return {
        tab: {
            border: '1px solid',
            borderColor: '#303030',
            bg: '#111111',
            borderTopRadius: 'lg',
            borderBottom: 'none',
            _selected: {
                bg: '#434343',
                color: 'white',
                borderColor: 'inherit',
                borderBottom: '#303030',
            },
        },
        tablist: {
            borderBottom: '1x solid',
            borderColor: '#303030',
        },
        tabpanel: {
            border: '1px solid',
            borderColor: '#303030',
            borderBottomRadius: 'lg',
            borderTopRightRadius: 'lg',
        },
    }
})

const variants = {
    colorful: colorfulVariant,
}

// export the component theme
const tabsTheme = defineMultiStyleConfig({ variants })

const theme = extendTheme({
    components: {
        Tabs: tabsTheme,
        Alert: {
            baseStyle: {
                variant: 'custom',
                status: 'info',
            },
            defaultProps: {
                variant: 'custom',
                status: 'info',
            },
            variants: {
                custom: {
                    container: {
                        border: '1px solid',
                        color: 'white',
                        borderColor: '#2E2E2E',
                        background: '#232525',
                    },
                    icon: {
                        color: '#30e2ff', 
                    },
                },
            },
        },
        Button: {
            baseStyle: {
                _disabled: {
                    cursor: "default",
                },
            },
            variants: {
                solid: {
                    bg: "#303030",
                    color: "white",
                    _hover: {
                        bg: "#434343",
                    },
                    _active: {
                        bg: "gray",
                    },
                },
                ghost: {
                    color: "white",
                    _hover: {
                        bg: "#303030",
                    },
                    _active: {
                        bg: "#434343",
                    },
                },
                outline: {
                    borderColor: '#373737',
                    color: "white",
                    _hover: {
                        bg: "#303030",
                    },
                    _active: {
                        bg: "#434343",
                    },
                },
                oauth: {
                    border: "1px solid",
                    borderColor: "#464848",
                    _hover: {
                        bg: "#1A73E8",
                    },
                    _active: {
                        bg: "#5194EE",
                    },
                },
            },
        },
    },
    styles: {
        global: {
            // 适用于 WebKit 的样式
            "::-webkit-scrollbar": {
                width: "12px",
            },
            "::-webkit-scrollbar-thumb": {
                backgroundColor: "#2B2B31",
                borderRadius: "full",
            },
            "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#434343",
            },
            "::-webkit-scrollbar-track": {
                backgroundColor: "inherit",
            },
            // 适用于 Firefox 的样式
            "*": {
                scrollbarWidth: "thin",
                scrollbarColor: "#2B2B31",
            },
            "*::-moz-scrollbar-thumb": {
                backgroundColor: "#2B2B31",
                borderRadius: "full",
            },
            "*::-moz-scrollbar-thumb:hover": {
                backgroundColor: "#434343",
            },
            "*::-moz-scrollbar-track": {
                backgroundColor: "inherit",
            },
        },
    },
});

export default theme;