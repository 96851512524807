// export const IP_ADDR = '192.168.3.108:8008'
// export const IP_ADDR = '43.128.94.142:8008'
export const VERSION = '1.0.6'
// export const IP_ADDR = '127.0.0.1:8008'
// export const HTTP_HOST = 'http://' + IP_ADDR
// export const WEBSOCKET_HOST = "ws://" + IP_ADDR
export const IP_ADDR = 'aibeatx.com'
export const HTTP_HOST = 'https://' + IP_ADDR
export const WEBSOCKET_HOST = "wss://" + IP_ADDR
export const CREATE_BASE_URL = HTTP_HOST + '/api/create/read-make/?'
export const FAVOR_BASE_URL = HTTP_HOST + '/api/music/get-favor/?'
export const GOOGLE_OAUTH_URL = HTTP_HOST + '/api/auth/google/authorize'
export const GOOGLE_OAUTH_CALLBACK_URL = HTTP_HOST + '/api/auth/google/callback'
export const FILE_OPEN_ACCEPT = '.mid,.midi,.wav,.mp3,.aac,.mp4,.ogg'