import {Box, Button, Flex, Heading, Link, Text} from '@chakra-ui/react';
import React from "react";
import {Link as RouterLink} from "react-router-dom";

const TermsOfUse = () => {
    return (
        <Box py={6} px={50} bg={'#080808'} overflowY="auto" maxH="calc(100vh)">
            <Flex justify="center" align="center" mb={4}>
                <Heading as="h1" size="xl" color={'white'}>
                    Terms of Use
                </Heading>
                <Box ml="auto">
                    <Button as={RouterLink} to="/" variant="outline">
                        Back
                    </Button>
                </Box>
            </Flex>

            <Heading as="h3" size="md" mb={2} color={'white'}>
                1. General
            </Heading>
            <Text mb={4} color={'white'}>
                These Terms of Use ("Terms") apply to the use of the AIBeatX website (www.aibeatx.com), including AIBeatX's online music catalog service (the "Service") and any software applications or content accessed through AIBeatX. By using the Service, you agree to be bound by these Terms. AIBeatX reserves the right to modify these Terms at any time and will publish such modifications on the website or notify you by other appropriate means. Your continued use of the Service constitutes your acceptance of the modified Terms. Please check the website regularly to review updates to the Terms. If you do not agree to comply with these Terms (or cannot comply), please discontinue use of the website and Service.
            </Text>

            <Heading as="h3" size="md" mb={2} color={'white'}>
                2. Service and General Limitations of Use
            </Heading>
            <Text mb={4} color={'white'}>
                AIBeatX is an online music creation software, audio editing software, royalty-free music catalog, and distribution provider through which AIBeatX generates, modifies, and/or provides new or existing digital audio files (collectively referred to as "Content"). As a distribution service, and through third-party providers, the Service manages the release of digital audio files to audio streaming platforms and the collection and payout of royalty revenues. User usage of Content may be as standalone music files or combined with other media as specified under the terms and conditions of the License Agreement.
                To use the Service and access Content, you must register and create a user account, possess the legal capacity to enter into binding contracts (i.e., mental capacity to enter into agreements), and not be prohibited from doing so under applicable law. If you are under 16 years of age or unable to independently enter into an agreement with AIBeatX, please contact <Link href="mailto:help@aibeatx.com" color="#30E2FF">support@aibeatx.com</Link>.
                Content availability may be amended by AIBeatX at any time, with or without notice to you. You acknowledge that Content available at one time may not be available at a later date.
                Content types and descriptions (such as genres, categories, etc.) are provided for convenience only and are subject to AIBeatX's unique perspective and best efforts to provide context.
                You agree not to use or launch any automated system, including but not limited to any robot, spider, or offline reader, that accesses the Service in a manner that sends more requests to AIBeatX or its servers in a given period of time than a human can reasonably produce using a standard web browser (i.e., not modified). Access to Content and the Service via an Application Programming Interface (API), if available, may be granted solely through a separate contract with its own terms and conditions, including limitations on request volume to the Service.
                You agree to download Content only in accordance with permissions and restrictions applicable to your account.
            </Text>

            <Heading as="h3" size="md" mb={2} color={'white'}>
                3. Your Account
            </Heading>
            <Text mb={4} color={'white'}>
                To use the Service, you must create a personal account and register as a user, following the instructions provided in the user registration form. Your account will include basic account information, including username and password. You are responsible for your username and password and must not share your password with anyone else.
                You may not select or use another person's identity with the intent to impersonate that person. You must use a valid email address, and AIBeatX reserves the right to test and verify it at any time. It is prohibited to agree with third parties on the transfer, utilization, or provision of accounts, resources, or access data.
                You must immediately notify AIBeatX of any security breach or unauthorized use of your AIBeatX account of which you become aware. You agree that you are solely responsible for all activity that occurs under your AIBeatX account.
            </Text>

            <Heading as="h3" size="md" mb={2} color={'white'}>
                4. Disclaimer
            </Heading>
            <Text mb={4} color={'white'}>
                AIBeatX will make reasonable efforts to keep the Service operational. However, technical issues or maintenance may occasionally result in interruptions.
                AIBeatX is not responsible for the unavailability or deficiencies of the Service caused by you, deficiencies in internet access, or any other event beyond AIBeatX's control.
                AIBeatX will periodically perform updates and maintenance on the Service, during which the Service may be unavailable.
                THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE," AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, AIBeatX MAKES NO WARRANTY, REPRESENTATION, EXPRESS OR IMPLIED, AND/OR DISCLAIMER REGARDING AVAILABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CONTENT OR OTHER DATA PROVIDED, AND THE QUALITY OF THE SERVICE. YOU USE THE SERVICE AT YOUR OWN RISK. YOU ALSO ACKNOWLEDGE THAT FREEDOM FROM PROGRAM ERRORS CANNOT BE GUARANTEED IN THE SOFTWARE INDUSTRY. NEITHER AIBeatX NOR ANY CONTENT OWNER WARRANTS THAT THE SERVICE IS FREE OF MALWARE OR OTHER HARMFUL COMPONENTS.
                AIBeatX (INCLUDING BUT NOT LIMITED TO AFFILIATED COMPANIES AND THEIR CONTRACTORS, OFFICERS, DIRECTORS, AND EMPLOYEES) SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCES, AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, FOR ANY DAMAGES ARISING FROM SHUTDOWN, LOSS OR EFFECT ON DATA, LOSS OF PROFITS, CLAIMS FOR DAMAGES BY A THIRD PARTY, OR OTHER INDIRECT INJURY.
            </Text>

            <Heading as="h3" size="md" mb={2} color={'white'}>
                5. Severability
            </Heading>
            <Text mb={4} color={'white'}>
                If any provision of these Terms is held to be invalid or unenforceable at any time or to any extent, the remainder of these Terms shall not be affected thereby. Each provision of these Terms shall be valid and enforceable to the fullest extent permitted by law.
            </Text>

            <Heading as="h3" size="md" mb={2} color={'white'}>
                6. Additional Information or Questions
            </Heading>
            <Text mb={4} color={'white'}>
                For further information regarding these Terms, please feel free to contact us at our email address <Link href="mailto:support@aibeatx.com" color="#30E2FF">support@aibeatx.com</Link>.
            </Text>
        </Box>
    );
};

export default TermsOfUse;
